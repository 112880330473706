<template>
  <div id="projects-form-edit">
    <b-card title="Datos">
      <div class="divInfo" :style="sheet">
        <p class="flex">
          <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
          Campos obligatorios (*)
        </p>
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Nombre *" label-for="name">
            <b-form-input id="name" v-model="editForm.project_name" name="name" placeholder="Ej: Mirador del sur"
              :state="project_nameErrors.length > 0 ? false : null" @input="$v.editForm.project_name.$touch()" />
            <small class="text-danger">
              {{ project_nameErrors[0] }}
            </small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Tipo de propiedad *" label-for="property_type">
            <v-select v-model="editForm.property_type" label="name" placeholder="Selecciona una propiedad"
              :options="property_type" :reduce="(property_type) => property_type.id"
              @input="$v.editForm.property_type.$touch()">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ property_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Tipo de subsidio *" label-for="subsidy_type">
            <v-select v-model="editForm.subsidy_type" label="name" multiple placeholder="Selecciona un subsidio"
              :options="subsidyTypes" :reduce="(subsidyTypes) => subsidyTypes.id"
              @input="$v.editForm.subsidy_type.$touch()">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ subsidy_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <!--<b-col cols="12" md="4">
            <b-form-group label="Etapa de venta *" label-for="construction_stage">
              <v-select
                  v-model="editForm.project_status_id"
                  label="name"
                  placeholder="Selecciona una etapa"
                  :options="projectStatus"
                  :reduce="projectStatus => projectStatus.id"
                  @input="$v.editForm.project_status_id.$touch()">
                <span slot="no-options">
                  No se encontraron opciones
                </span>
              </v-select>
              <small class="text-danger">{{ construction_stageErrors[0] }}</small>
            </b-form-group>
          </b-col>-->

        <!--<b-col cols="12" md="4">
            <b-form-group label="Etapa de construcción" label-for="property_type">
              <v-select
                v-model="editForm.project_stage"
                label="name"
                placeholder="Selecciona un estado"
                :options="projectStage"
                :reduce="projectStage => projectStage.id">
                <span slot="no-options">
                  No se encontraron opciones
                </span>
              </v-select>
            </b-form-group>
          </b-col>-->

        <b-col cols="12" md="4">
          <b-form-group label="Inmobiliaria *" label-for="construction_stage">
            <v-select v-model="editForm.real_state_agency" label="name" placeholder="Selecciona una inmobiliaria"
              :options="realStateAgencies" :reduce="(realStateAgencies) => realStateAgencies.id"
              @input="$v.editForm.real_state_agency.$touch()">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ real_state_agencyErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <!--<b-col cols="12" md="4">
            <b-form-group label="Proyectos de interés" label-for="construction_stage">
              <v-select
                v-model="editForm.projects_related"
                label="name"
                multiple
                placeholder="Selecciona uno o mas proyectos"
                :options="projects"
                :reduce="projects => projects.id"
                v-on:input="maxProjects">
                <span slot="no-options">
                  No se encontraron opciones
                </span>
              </v-select>
            </b-form-group>
          </b-col>-->

        <!--<b-col cols="12" md="4">
            <b-form-group label="Resumen proyecto" label-for="summary">
              <b-form-input id="summary"
                v-model="editForm.summary"
                name="summary"
                placeholder="Ej: Un proyecto con mas de 50 deptos" />
            </b-form-group>
          </b-col>-->

        <!--<b-col cols="12" md="4">
            <b-form-group label="Vigencia de cotización *" label-for="quotation_validity">
              <b-form-input id="quotation_validity"
                v-model="editForm.quotation_validity"
                name="quotation_validity"
                placeholder="Ej: 30"
                :state="quotation_validityErrors.length > 0 ? false:null"
                @input="$v.editForm.quotation_validity.$touch()"/>
                <small class="text-danger">{{ quotation_validityErrors[0] }}</small>
            </b-form-group>
          </b-col>-->

        <!--<b-col cols="12" md="4">
            <b-form-group label="Vigencia de reserva *" label-for="reserve_validity">
              <b-form-input id="reserve_validity"
                v-model="editForm.reserve_validity"
                name="reserve_validity"
                placeholder="Ej: 30"
                :state="reserve_validityErrors.length > 0 ? false:null"
                @input="$v.editForm.reserve_validity.$touch()"/>
              <small class="text-danger">{{ reserve_validityErrors[0] }}</small>
            </b-form-group>
          </b-col>-->

        <b-col cols="12" md="4" v-show="show_admin_input">
          <b-form-group label="Orden" label-for="orden">
            <b-form-input id="orden" v-model="editForm.orden" name="orden" placeholder="Ej: 1"
              :state="ordenErrors.length > 0 ? false : null" />
          </b-form-group>
          <small class="text-danger">{{ ordenErrors[0] }}</small>
        </b-col>
        <b-col cols="12" md="4" v-show="false">
          <b-form-group label="Tipo de proyecto" label-for="tipo_proyecto">
            <v-select v-model="editForm.type" label="name" placeholder="Selecciona un tipo de proyecto"
              :options="projects_type" :reduce="(projects_type) => projects_type.id">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group label="Sala de venta" label-for="sales_room">
            <b-form-input id="sales_room" v-model="editForm.sales_room" name="sales_room" placeholder="Sala de venta"
              @input="$v.editForm.sales_room.$touch()" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Descripción del Proyecto</p>
            <ckeditor id="editDescription" :editor="editor" v-model="editForm.project_description"
              :config="editorConfig"></ckeditor>
          </div>
        </b-col>

        <b-col cols="12 mt-2">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Promociones del Proyecto</p>
            <ckeditor :editor="editor" v-model="editForm.promotions" :config="editorConfig"></ckeditor>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Detalles">
      <b-row>
        <b-col cols="12" md="6" v-show="show_admin_input">
          <b-form-group label="CRM ID" label-for="crm_id">
            <b-form-input id="crm_id" v-model="editForm.crm_id" name="crm_id" placeholder="Identificador de CRM" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="CRM Correo" label-for="crm_email">
            <b-form-input id="crm_email" v-model="editForm.crm_email" name="crm_id" placeholder="Correo de CRM"
              :state="crm_email_listErrors.length > 0 ? false : null" @input="$v.editForm.crm_email.$touch()" />
            <small class="text-danger">{{ crm_email_listErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" v-show="show_admin_input">
          <b-form-group label="CRM ZP ID" label-for="crm_zp">
            <b-form-input id="crm_zp" v-model="editForm.crm_zp" name="crm_zp" placeholder="Identificador de CRM ZP" />
          </b-form-group>
        </b-col>
        <b-col cols="12" :md="show_admin_input ? 4 : 6">
          <b-form-group label="Precio desde *" label-for="price_from">
            <b-form-input id="price_from" v-model="editForm.price_from" name="price_from" placeholder="Ej: 1.500"
              :state="price_fromErrors.length > 0 ? false : null" @input="$v.editForm.price_from.$touch()" />
            <small class="text-danger">{{ price_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <!--<b-col cols="12" md="4">
          <b-form-group label="Baños desde" label-for="bathrooms_from">
            <b-form-input id="bathrooms_from *"
              v-model="editForm.bathrooms_from"
              name="bathrooms_from"
              placeholder="Ej: 1 y 2"
              :state="bathrooms_fromErrors.length > 0 ? false:null"
              @input="$v.editForm.bathrooms_from.$touch()"/>
            <small class="text-danger">{{ bathrooms_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>-->

        <b-col cols="12" md="4">
          <b-form-group label="Dormitorios desde *" label-for="bedrooms_from">
            <b-form-input id="bedrooms_from" v-model="editForm.bedrooms_from" name="bedrooms_from"
              placeholder="Ej: 1 , 2 y 3" :state="bedrooms_fromErrors.length > 0 ? false : null"
              @input="$v.editForm.bedrooms_from.$touch()" />
            <small class="text-danger">{{ bedrooms_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Área desde *" label-for="area_from">
            <b-form-input id="area_from" v-model="editForm.area_from" name="area_from" placeholder="Ej: 60 a 100"
              :state="area_fromErrors.length > 0 ? false : null" @input="$v.editForm.area_from.$touch()" />
            <small class="text-danger">{{ area_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col v-for="atraction in atractions" :key="atraction.name_en" cols="12" md="3">
          <b-form-checkbox :id="atraction.name_en" v-model="editForm[atraction.name_en]" :name="atraction.name_en"
            :value="true" :unchecked-value="false">
            {{ atraction.name_es }}
          </b-form-checkbox>
        </b-col>

        <!--<b-col cols="12" md="4">
          <b-form-group label="Fecha entrega" label-for="deliver_date">
            <b-form-input id="deliver_date"
              v-model="editForm.deliver_date"
              name="deliver_date"
              placeholder="Ej: Primer Semestre 2023"/>
          </b-form-group>
        </b-col>-->

        <!--<b-col cols="12">
          <label for="legal">Legal Cotizaciones</label>
          <b-form-textarea
            v-model="editForm.legal_quote_text"
            id="legal"
            placeholder="Texto legal de cotizaciones"
            rows="3" />
        </b-col>-->
      </b-row>
    </b-card>

    <b-card title="Características Ventas">
      <b-row>
        <b-col xl="4" sm="12">
          <b-form-group label="Api key" label-for="api_key">
            <b-input-group>
              <b-form-input id="api_key" v-model="editForm.api_key" name="api_key" :type="passwordFieldTypeApiKey"
                placeholder="············" />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIconApiKey"
                  @click="togglePasswordVisibilityApiKey()" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col xl="4" sm="12">
          <b-form-group label="Secret key" label-for="secret_key">
            <b-input-group>
              <b-form-input id="secret_key" v-model="editForm.secret_key" name="secret_key"
                :type="passwordFieldTypeSecretKey" placeholder="············" />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIconSecretKey"
                  @click="togglePasswordVisibilitySecretKey()" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Integrar CRM" label-for="integration_crm">
            <v-select v-model="editForm.integration_crm" label="name" placeholder="Seleccione" :options="list_crm">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Renta mínima" label-for="minimumSalary">
            <b-form-input type="number" min="0" v-model="editForm.minimumSalary" name="minimumSalary"
              placeholder="Renta mínima" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="d-flex flex-column justify-content-center">
          <b-form-checkbox id="reserve_crm" v-model="editForm.reserve_crm" name="reserve_crm" :value="true"
            :unchecked-value="false">
            Enviar reserva CRM
          </b-form-checkbox>
          <b-form-checkbox id="reserve_crm_zp" v-model="editForm.reserve_crm_zp" name="reserve_crm_zp" :value="true"
            :unchecked-value="false">
            Enviar reserva CRM ZP
          </b-form-checkbox>
        </b-col>
        <!--
        <b-col xl="4" sm="12">
          <b-form-group label="Tipo de Promesa *" label-for="reserve_type">
            <v-select
                v-model="editForm.promises_type"
                label="name"
                placeholder="Selecciona una opción"
                :options="reserve_type"
                :reduce="reserve_type => reserve_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ promises_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="4" sm="12">
          <b-form-group label="Tipo de Reserva *" label-for="reserve_type">
            <v-select
                v-model="editForm.reserve_type"
                label="name"
                placeholder="Selecciona una opción"
                :options="reserve_type"
                :reduce="reserve_type => reserve_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ reserve_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="4" sm="12">
          <b-form-group label="Moneda *" label-for="money_type">
            <v-select
                :clearable="false"
                v-model="editForm.money_type"
                label="name"
                placeholder="Selecciona una opción"
                :options="money_type"
                :reduce="money_type => money_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ money_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="4" sm="12">
          <b-form-group label="Monto *" label-for="reserve_amount">
            <b-form-input id="reserve_amount"
              v-model="editForm.reserve_amount"
              name="reserve_amount"
              placeholder="Ej: 2000"
              :state="reserve_amountErrors.length > 0 ? false:null"
              @input="$v.editForm.reserve_amount.$touch()"/>
            <small class="text-danger">{{ reserve_amountErrors[0] }}</small>
          </b-form-group>
        </b-col>
        -->
      </b-row>
    </b-card>

    <b-card title="Ubicación">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar la dirección en el casillero Dirección del proyecto
            y elegirla de la lista desplegable que aparecerá o puede mover el
            marcador en el mapa hasta el lugar donde está ubicado el proyecto
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="3">
            <b-form-group label="Dirección *" label-for="autocomplete">
              <input class="form-control" v-model="editForm.address" name="address" ref="autocomplete" id="autocomplete"
                placeholder="Dirección del proyecto" :state="addressErrors.length > 0 ? false : null" />
              <small class="text-danger">{{ addressErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Región *" label-for="region">
              <v-select v-model="editForm.region" label="name" placeholder="Selecciona una región" :options="locations"
                :reduce="(region) => region.id" v-on:input="checkRegion" @input="$v.editForm.region.$touch()">
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ regionErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Provincia *" label-for="province">
              <v-select :disabled="editForm.region === '' || editForm.region === null" v-model="editForm.province"
                label="name" placeholder="Selecciona una provincia" :options="provinces_list"
                :reduce="(province) => province.id" v-on:input="checkProvince" @input="$v.editForm.province.$touch()">
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ provinceErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Comuna *" label-for="commune">
              <v-select :disabled="editForm.province === '' || editForm.province === null
                " v-model="editForm.commune" label="name" placeholder="Selecciona una comuna" :options="communes_list"
                :reduce="(commune) => commune.id" @input="$v.editForm.commune.$touch()">
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ communeErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" md="6">
            <b-form-group label="Latitud *" label-for="latitude">
              <b-form-input id="latitude" v-model="editForm.latitude" name="latitude" placeholder="Coordenada latitud"
                :state="latitudeErrors.length > 0 ? false : null" @input="$v.editForm.latitude.$touch()" />
              <small class="text-danger">{{ latitudeErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Longitud *" label-for="longitude">
              <b-form-input id="longitude" v-model="editForm.longitude" name="longitude"
                placeholder="Coordenada longitud" :state="longitudeErrors.length > 0 ? false : null"
                @input="$v.editForm.longitude.$touch()" />
              <small class="text-danger">{{ longitudeErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <gmaps-map :options="mapOptions" style="width: 100%; height: 500px" @mounted="ready">
              <gmaps-marker :options="markerOptions" @move="updatePosition" />
            </gmaps-map>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Restricciones" v-show="show_admin_input">
      <div>
        <b-row class="my-1">
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Vende subsidios" label-for="sell_subsidies">
              <v-select v-model="editForm.sell_subsidies" label="name" placeholder="Selecciona una opción"
                :options="answers" :reduce="(answers) => answers.id">
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Planes adicionales" label-for="plans_additional">
              <v-select v-model="editForm.plans_additional" label="name" multiple
                placeholder="Selecciona el plan adicional" :options="plansAdditional"
                :reduce="(plansAdditional) => plansAdditional.id" @input="$v.editForm.plans_additional.$touch()">
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Chatbot URL" label-for="chatbot_url">
              <b-form-input id="chatbot_url" v-model="editForm.chatbot_url" name="chatbot_url"
                :state="chatbotURLErrors.length > 0 ? false : null" @input="$v.editForm.chatbot_url.$touch()" />
              <small class="text-danger">{{ chatbotURLErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Medias">
      <div>
        <b-row class="my-1">
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Brochure" label-for="brochure">
              <b-form-input id="brochure" v-model="editForm.brochure" name="brochure"
                placeholder="Ej: http://www.ejemplo.cl" :state="brochureErrors.length > 0 ? false : null"
                @input="$v.editForm.brochure.$touch()" />
              <small class="text-danger">{{ brochureErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Nube360" label-for="nube360">
              <b-form-input id="nube360" v-model="editForm.nube360" name="nube360"
                placeholder="Ej: http://www.ejemplo.cl" :state="nube360Errors.length > 0 ? false : null"
                @input="$v.editForm.nube360.$touch()" />
              <small class="text-danger">{{ nube360Errors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Tour360" label-for="tour360">
              <b-form-input id="tour360" v-model="editForm.tour360" name="tour360"
                placeholder="Ej: http://www.ejemplo.cl" :state="tour360Errors.length > 0 ? false : null"
                @input="$v.editForm.tour360.$touch()" />
              <small class="text-danger">{{ tour360Errors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="ID Video Youtube" label-for="video_youtube_id">
              <b-form-input id="video_youtube_id" v-model="editForm.video_youtube_id" name="video_youtube_id"
                placeholder="Ej: ASCGSRX12" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="d-flex align-items-center">
            <b-form-checkbox :id="'show_logo_realstate'" v-model="editForm.show_logo_realstate"
              :name="'show_logo_realstate'" :value="true" :unchecked-value="false">
              Mostrar logo inmobiliaria
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="d-flex align-items-center">
            <b-form-checkbox :id="'show_section_news'" v-model="editForm.show_section_news" :name="'show_section_news'"
              :value="true" :unchecked-value="false">
              Mostrar proyecto en el blog
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card class="title-contactos-ejecutivos">
      <b-row>
        <b-col cols="6">
          <h4 class="card-title">Contacto ejecutivo</h4>
        </b-col>
        <b-col cols="6"><b-button variant="primary" @click="addContactOrder()" class="float-right">
            <feather-icon icon="PlusIcon" class="mr-50" />Agregar
            ejecutivo</b-button></b-col>
        <b-col cols="12">
          <div class="divInfo" :style="sheet">
            <p class="flex">
              <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />Ingresa el nombre, imagen, email y
              teléfono del contacto
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col ols="12" sm="4" lg="3" v-for="(contact_order, indexC) in contacts_orders" :key="indexC">
          <div>
            <img class="image aca123" :src="contact_order.image != undefined
              ? contact_order.image.full_url
              : logoUserDefault
              " />
          </div>
          <div class="mt-2">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />{{
              contact_order.name
            }}
          </div>
          <div>
            <feather-icon size="16" icon="PhoneIcon" class="mr-50" />{{
              contact_order.phone
            }}
          </div>
          <div class="mb-2">
            <feather-icon size="16" icon="MailIcon" class="mr-50" />{{
              contact_order.email
            }}
          </div>
          <b-row>
            <b-col cols="6">
              <b-button variant="primary" @click="showModalEdit(contact_order, indexC)">Editar</b-button>
            </b-col>
            <b-col cols="6">
              <b-button variant="primary" class="float-right"
                @click="deleteContactOrder(contact_order, indexC)">Eliminar</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="danger-text" v-if="$v.editForm.executive_name_list.$error">
        {{ executive_name_listErrors[0] }}
      </div>
    </b-card>
    <b-card title="Contacto ejecutivo" class="d-none">
      <div class="divInfo" :style="sheet">
        <p class="flex">
          <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
          Ingresa un nombre, teléfono o email, has click en el cualquiera de los
          datos ingresados para eliminarlo
        </p>
      </div>
      <b-row class="my-1">
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input id="executive_name" v-model="executive_name" name="executive_name"
              placeholder="Nombre de ejecutivo" :state="executive_nameErrors.length > 0 ? false : null"
              @input="$v.executive_name.$touch()" />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('name')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_nameErrors[0] }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input id="executive_phone" v-model="executive_phone" name="executive_phone"
              placeholder="Teléfono de ejecutivo" :state="executive_phoneErrors.length > 0 ? false : null"
              @input="$v.executive_phone.$touch()" />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('phone')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_phoneErrors[0] }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input id="executive_email" v-model="executive_email" name="executive_email"
              placeholder="Email de ejecutivo" :state="executive_emailErrors.length > 0 ? false : null"
              @input="$v.executive_email.$touch()" />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('email')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_emailErrors[0] }}</small>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="danger-text" v-if="$v.editForm.executive_name_list.$error">
            {{ executive_name_listErrors[0] }}
          </div>
          <h4>
            <b-button size="sm" class="mr-1 mb-1" href="#" variant="primary" @click="_removeExecutive(name, 'name')"
              v-for="name in editForm.executive_name_list" :key="name" closable>
              <span class="align-middle">
                {{ name }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
        <b-col>
          <div class="danger-text" v-if="$v.editForm.executive_phone_list.$error">
            {{ executive_phone_listErrors[0] }}
          </div>
          <h4>
            <b-button size="sm" class="mr-1 mb-1" href="#" variant="primary" @click="_removeExecutive(phone, 'phone')"
              v-for="phone in editForm.executive_phone_list" :key="phone" closable>
              <span class="align-middle">
                {{ phone }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
        <b-col>
          <div class="danger-text" v-if="$v.editForm.executive_email_list.$error">
            {{ executive_email_listErrors[0] }}
          </div>
          <h4>
            <b-button size="sm" class="mr-1 mb-1" href="#" variant="primary" @click="_removeExecutive(email, 'email')"
              v-for="email in editForm.executive_email_list" :key="email" closable>
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col cols="12">
          <b-form-file v-model="gallery_contacts" accept=".jpeg, .jpg, .png" browse-text="Formatos: jpeg - jpg - png"
            multiple placeholder="Selecciona o arrastra varias imágenes" drop-placeholder="Arrastra aquí la imagen"
            @change="_previewFileGalleryContact"></b-form-file>
          <b-row class="mt-2">
            <b-col v-if="this.editForm.gallery_contacts.length === 0" cols="12">
              <div class="preview">
                <h5>Previsualización Contacto</h5>
              </div>
            </b-col>
            <b-col v-else cols="12" sm="4" lg="3" v-for="(img, i) in this.editForm.gallery_contacts" :key="i">
              <div class="container">
                <img class="image" :src="img != undefined ? img.src : ''" />
                <div class="mt-1 mb-1" align="center">
                  <b-button @click="_deleteGalleryContact(i, img)" variant="primary">
                    <feather-icon icon="SaveIcon" class="mr-50" />
                    <span class="align-middle"> Eliminar </span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <!--
    <b-card title="Receptor de desistimiento">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon
                size="25"
                icon="AlertCircleIcon"
                class="mx-50 my-1"/>
            Puedes ingresar un receptor o varios, has click en un correo ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_disengagement"
                v-model="email_disengagement"
                name="email_disengagement"
                placeholder="Email de receptor(es)"
                :state="email_disengagementErrors.length > 0 ? false:null"
                @input="$v.email_disengagement.$touch()"/>
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('disengagement')">
                  <feather-icon
                      icon="PlusIcon"
                      class="mr-50"/>
                  Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_disengagementErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.editForm.email_disengagement_list.$error">{{email_disengagement_listErrors[0]}}</div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'disengagement')" v-for="email in editForm.email_disengagement_list" :key="email" closable>
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon
                  icon="XIcon"
                  class="mr-50"/>
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>
    -->

    <b-card title="Receptor de cotizaciones">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar un receptor o varios, has click en un correo
            ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_quotation" v-model="email_quotation" name="email_quotation"
                placeholder="Email de receptor(es)" :state="email_quotationErrors.length > 0 ? false : null"
                @input="$v.email_quotation.$touch()" />
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('quotation')">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_quotationErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.editForm.email_quotation_list.$error">
            {{ email_quotation_listErrors[0] }}
          </div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'quotation')"
              v-for="email in editForm.email_quotation_list" :key="email" closable>
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>

    <b-card title="Receptor de reservas">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar un receptor o varios, has click en un correo
            ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_booking" v-model="email_booking" name="email_booking"
                placeholder="Email de receptor(es)" :state="email_bookingErrors.length > 0 ? false : null"
                @input="$v.email_booking.$touch()" />
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('booking')">
                  <feather-icon icon="PlusIcon" class="mr-50" /> Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_bookingErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.editForm.email_booking_list.$error">
            {{ email_booking_listErrors[0] }}
          </div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'booking')"
              v-for="email in editForm.email_booking_list" :key="email" closable>
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>

    <!--
    <b-card title="Receptor de promesas">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon
                size="25"
                icon="AlertCircleIcon"
                class="mx-50 my-1"/>
            Puedes ingresar un receptor o varios, has click en un correo ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_promise"
                v-model="email_promise"
                name="email_promise"
                placeholder="Email de receptor(es)"
                :state="email_promiseErrors.length > 0 ? false:null"
                @input="$v.email_promise.$touch()"/>
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('promise')">
                  <feather-icon icon="PlusIcon" class="mr-50"/> Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_promiseErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.editForm.email_promise_list.$error">{{email_promise_listErrors[0]}}</div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'promise')" v-for="email in editForm.email_promise_list" :key="email" closable>
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon
                  icon="XIcon"
                  class="mr-50"/>
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>
    -->
    <b-card title="Fotografías de presentación">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Estas imágenes son las que se utilizarán como miniatura del proyecto
            y como imagen de portada
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="6">
            <b-form-file v-model="miniature" accept=".jpeg, .jpg, .png" browse-text="Formatos: jpeg - jpg - png"
              placeholder="Selecciona o arrastra una imagen" drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFileMiniature">
            </b-form-file>
            <small class="text-danger">{{ miniatureErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="editForm.miniature === ''" cols="12">
                <div class="preview">
                  <h5>Previsualización Miniatura</h5>
                  <h5><strong>1366x768</strong></h5>
                </div>
              </b-col>
              <b-col v-else cols="12">
                <div class="container" width="300">
                  <img class="image" :src="editForm.miniature.src" />
                </div>
              </b-col>
              <b-col v-if="editForm.miniature != ''" cols="12" class="mt-1" align="center">
                <b-button variant="primary" @click="_deleteMiniature()">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle"> Eliminar </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!--
          <b-col cols="12" md="6">
            <b-form-file
              v-model="portrait"
              accept=".jpeg, .jpg, .png"
              browse-text="Formatos: jpeg - jpg - png"
              placeholder="Selecciona o arrastra una imagen"
              drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFilePortrait">
            </b-form-file>
            <small class="text-danger">{{ portraitErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="editForm.portrait === ''" cols="12">
                <div class="preview">
                  <h5>Previsualización Portada</h5>
                  <h5><strong>1366x768</strong></h5>
                </div>
              </b-col>
              <b-col v-else cols="12">
                <div class="container" width="300">
                  <img class="image" :src="editForm.portrait.src">
                </div>
              </b-col>
              <b-col v-if="editForm.portrait != ''" cols="12" class="mt-1" align="center">
                <b-button variant="primary" @click="_deletePortrait()">
                  <feather-icon
                      icon="SaveIcon"
                      class="mr-50"/>
                  <span class="align-middle">
                    Eliminar
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          -->
        </b-row>
      </div>
    </b-card>

    <b-card title="Fotografías de galería">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Adjunta alguna(s) imágen(es) del proyecto para poder mostrarlas en
            la galería del proyecto
          </p>
        </div>
        <div>
        </div>
        <b-row class="my-1">
          <b-col cols="12">
            <b-form-file v-model="gallery" accept=".jpeg, .jpg, .png" browse-text="Formatos: jpeg - jpg - png" multiple
              placeholder="Selecciona o arrastra varias imágenes" drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFileGallery">
            </b-form-file>
            <small class="text-danger">{{ galleryErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="this.editForm.gallery.length === 0" cols="12">
                <div class="preview">
                  <h5>Previsualización Galería</h5>
                  <h5><strong>800x392</strong></h5>
                </div>
              </b-col>
              <b-col v-else cols="12" sm="4" lg="3" v-for="(img, i) in listImages" :key="i" draggable="true"
                @dragstart="startDrag2($event, i)" @dragover="onDragOver2($event, i)" @drop="onDrop2($event, i)">
                <div class="container">
                  <img class="image" :src="img.src" />
                  <div class="mt-1 mb-3" align="center">
                    <b-button @click="_deleteGallery(i, img)" variant="primary">
                      <feather-icon icon="SaveIcon" class="mr-50" />
                      <span class="align-middle"> Eliminar </span>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Cargar unidades">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Adjunta un excel con las unidades disponibles, este excel debe tener
            el formato que se presenta en el ejemplo para descarga. Cargar las
            unidades es de carácter opcional
          </p>
        </div>
        <b-row class="px-1">
          <b-button class="my-1" variant="primary" @click="_exportDemoProperties()">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle"> Descargar Ejemplo </span>
          </b-button>
        </b-row>
        <b-row class="my-1">
          <b-col class="my-1" vs-w="12">
            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display: none"
              id="excelEditInput" type="file" @change="_previewFileExcel" />
            <label class="inputLabel" for="excelEditInput">Selecciona un archivo</label>
            <b-row class="mt-2">
              <b-col v-if="this.createForm.excel.length === 0" vs-w="3">
                <div class="preview">
                  <h5>Archivo</h5>
                </div>
              </b-col>
              <b-col v-else vs-w="3">
                <div class="container">
                  <div class="preview">
                    <h5 class="mt-12">{{ this.createForm.excel.name }}</h5>
                  </div>
                </div>
              </b-col>
              <b-col v-if="this.createForm.excel.length > 0" cols="12" class="mt-1" align="center">
                <b-button variant="primary" @click="_deleteExcel()">Eliminar</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal id="excel-edit-modal" ok-only centered button-size="md" ok-title="Aceptar" no-close-on-backdrop
          no-close-on-esc title="Corregir los siguientes campos y subir nuevamente el archivo Excel">
          <div v-for="(data, x) in this.infoExcelProperty" :key="x">
            <h3>Identificador: {{ data.identifier }}</h3>
            <div v-if="!data.empty">
              <div v-for="(msg, y) in data.messages" :key="y">
                <h6 style="color: red">- {{ msg }}</h6>
              </div>
            </div>
            <div v-else>
              <h6 style="color: green">- No hay correciones para esta fila</h6>
            </div>
            <br />
          </div>
        </b-modal>
      </div>
    </b-card>
    <b-card title="Unidades">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Adjunta una imagen por cada planta disponible, para realizar un
            cambio debes eliminar previamente la imagen.
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="6">
            <v-select v-model="model_selected" label="name" placeholder="Selecciona un modelo" :options="models_list"
              :reduce="(model) => model.name" :selectable="(model) => model.disabled">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-file :disabled="model_selected === '' || model_selected === null" v-model="project_model"
              accept=".jpeg, .jpg, .png" browse-text="Formato png" multiple
              placeholder="Selecciona o arrastra una imagen" drop-placeholder="Arrastra aquí la imagen"
              @change="_previewModel">
            </b-form-file>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col v-if="this.project_models.length === 0" cols="12">
            <div class="preview">
              <h5>Previsualización Modelos</h5>
              <h5><strong>800x800</strong></h5>
            </div>
          </b-col>
          <b-col v-else cols="12" sm="4" lg="3" v-for="(model, i) in this.project_models" :key="i">
            <div class="container">
              <img class="image" :src="model.src" />
              <div class="mt-1 mb-3" align="center">
                <h5>Planta {{ model.model }}</h5>
                <b-button @click="_deleteModel(i, model)" variant="primary">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle"> Eliminar </span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row align-h="end">
      <div class="d-flex align-items-end justify-content-end mr-1 mt-1 mb-2">
        <div>
          <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block"
            @hidden="_onHidden()">
            <b-button ref="button" :disabled="overlayForm" variant="primary" @click.prevent="_saveMedias()">
              <feather-icon icon="SaveIcon" class="mr-50" />
              <span class="align-middle"> Guardar </span>
            </b-button>
          </b-overlay>
        </div>
      </div>
    </b-row>
    <b-modal hide-footer ref="modalContacts" title="Contactos" @hide="modalClosed">
      <b-form>
        <b-row>
          <b-col cols="12">
            <div>
              <b-form-group label="Nombre">
                <b-form-input v-model="$v.modalContact.name.$model" placeholder="Nombre" />
                <div v-if="$v.modalContact.name.$dirty">
                  <small class="text-danger" v-if="!$v.modalContact.name.required">Campo necesario</small>
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Telefono">
                <b-form-input v-model="$v.modalContact.phone.$model" placeholder="Telefono" />
                <div v-if="$v.modalContact.phone.$dirty">
                  <small class="text-danger" v-if="!$v.modalContact.phone.required">Campo necesario</small>
                  <small class="text-danger" v-if="
                    $v.modalContact.phone.required &&
                    !$v.modalContact.phone.numeric
                  ">Solo numeros</small>
                  <small class="text-danger" v-if="
                    $v.modalContact.phone.required &&
                    $v.modalContact.phone.numeric &&
                    !$v.modalContact.phone.minLength
                  ">Debes ingresar 9 dígitos</small>
                  <small class="text-danger" v-if="
                    $v.modalContact.phone.required &&
                    $v.modalContact.phone.numeric &&
                    $v.modalContact.phone.minLength &&
                    !$v.modalContact.phone.maxLength
                  ">Debes ingresar 9 dígitos</small>
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Email">
                <b-form-input v-model="$v.modalContact.email.$model" placeholder="Email" />
                <div v-if="$v.modalContact.email.$dirty">
                  <small class="text-danger" v-if="!$v.modalContact.email.required">Campo necesario</small>
                  <small class="text-danger" v-if="!$v.modalContact.email.email">Formato de email incorrecto</small>
                </div>
              </b-form-group>
            </div>
            <div>
              <b-col cols="12" sm="12" lg="12">
                <div class="container">
                  <img class="image imageModalImg" :src="modalContact.image != undefined
                    ? modalContact.image.full_url
                    : logoUserDefault
                    " />
                  <div class="mt-1 mb-1" align="center">
                    <b-button @click="_deleteGalleryContactModal()" variant="primary" v-if="
                      modalContact.image != undefined &&
                      modalContact.image != null
                    ">
                      <feather-icon icon="SaveIcon" class="mr-50" />
                      <span class="align-middle">Eliminar</span></b-button>
                  </div>
                </div>
              </b-col>
              <b-form-file v-model="gallery_contacts" accept=".jpeg, .jpg, .png"
                browse-text="Formatos: jpeg - jpg - png" placeholder="Selecciona o arrastra una imagen"
                drop-placeholder="Arrastra aquí la imagen" @change="_previewFileGalleryContactModal"></b-form-file>
            </div>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-button variant="primary" @click="saveContactOrder()" v-if="modalContact.isnew == '1'" class="float-right"
              :disabled="$v.modalContact.$invalid">Guardar2</b-button>
            <b-button variant="primary" @click="editContactOrderModal()" v-else class="float-right"
              :disabled="$v.modalContact.$invalid">Guardar3</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  required,
  numeric,
  email,
  minLength,
  maxLength,
  url,
  decimal,
  requiredIf,
} from "vuelidate/lib/validators";
import { gmaps, gmapsMap, gmapsMarker } from "x5-gmaps";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as rutHelpers from "rut-helpers";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

const isRutValid = (value) => rutHelpers.rutValidate(value);

export default {
  name: "ProjectsFormEdit",
  props: ["project"],
  components: { gmapsMap, gmapsMarker },
  data() {
    return {
      draggedIndex: null,
      parent: null,
      tapes: [
        "Depeche Mode",
        "Duran Duran",
        "Pet Shop Boys",
        "Kraftwerk",
        "Tears for Fears",
        "Spandau Ballet",
      ],
      logoUserDefault: require("@/assets/images/avatars/default.png"),
      permissions_visible: {
        project_edit_type: false,
      },
      answers: [
        { id: 0, name: "No" },
        { id: 1, name: "Si" },
      ],
      projects_type: [
        { id: "0", name: "Interno" },
        { id: "1", name: "Externo" },
      ],
      show_admin_input: false,
      passwordFieldTypeApiKey: "password",
      passwordFieldTypeSecretKey: "password",
      overlayForm: false,
      markerOptions: {
        position: { lat: -33.45694, lng: -70.64827 },
        title: "Marker A",
        draggable: true,
      },
      mapOptions: {
        center: { lat: -33.45694, lng: -70.64827 },
        zoom: 15,
      },
      markers: [],
      sheet: {
        backgroundColor: "#EFEFEF",
      },
      infoExcelProperty: [],
      editForm: {
        type: "",
        project_name: "",
        sales_room: "",
        property_type: "",
        subsidy_type: [],
        //project_status_id: '',
        real_state_agency: "",
        //projects_related: [],
        //quotation_validity: '',
        //reserve_validity: '',
        crm_id: "",
        crm_zp: "",
        crm_email: "",
        price_from: "",
        bedrooms_from: "",
        //bathrooms_from: '',
        area_from: "",
        //deliver_date: '',
        address: "",
        region: "",
        province: "",
        commune: "",
        latitude: "",
        longitude: "",
        length: "",
        brochure: "",
        nube360: "",
        tour360: "",
        video_youtube_id: "",
        api_key: "",
        secret_key: "",
        //money_type: '',
        //reserve_amount: '',
        //reserve_type: '',
        //promises_type: '',
        //legal_quote_text: '',
        executive_name_list: [],
        executive_phone_list: [],
        executive_email_list: [],
        email_query_list: [],
        email_quotation_list: [],
        //email_promise_list: [],
        email_booking_list: [],
        //email_disengagement_list: [],
        miniature: "",
        //portrait: '',
        gallery: [],
        gallery_contacts: [],
        gallery_contacts_saved: [],
        excel: [],
        //summary: '',
        sell_subsidies: 0,
        plans_additional: [],
        //project_stage:'',
        chatbot_url: "",
        exercise_area: false,
        green_area: false,
        bikers: false,
        games_accessibility: false,
        calisthenics_area: false,
        stores: false,
        events_room: false,
        clean_points: false,
        elevators: false,
        controlled_access: false,
        quincho: false,
        swimming_pool: false,
        reserve_crm: false,
        reserve_crm_zp: false,
        show_logo_realstate: false,
        hospital: false,
        subway_station: false,
        show_section_news: false,
        integration_crm: "",
        promotions: "",
      },
      createForm: {
        excel: [],
      },
      modalContact: {
        name: "",
        phone: "",
        email: "",
        image: "",
        isnew: "1",
        realIndex: "-1",
      },
      contacts_orders: [],
      region: "",
      province: "",
      miniature: [],
      //portrait: [],
      gallery: [],
      gallery_contacts: [],
      gallery_contacts_modal: [],
      excel: [],
      provinces_list: [],
      communes_list: [],
      property_type: [
        { id: "house", name: "Casa" },
        { id: "department", name: "Departamento" },
      ],
      /*money_type: [
          {id: "UF", name:"UF"},
          {id: "CLP", name:"CLP"}
        ],*/
      /*reserve_type: [
          {id:"Direct", name:"Directo"},
          {id:"Request", name:"Por Solicitud"}
        ],*/
      executive_name: "",
      executive_phone: "",
      executive_email: "",
      name_query: "",
      phone_query: "",
      email_query: "",
      email_quotation: "",
      //email_promise: "",
      email_booking: "",
      //email_disengagement: "",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "undo",
          "redo",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
        height: "100px",
      },
      jsonExcel: "",
      validJsonExcel: false,
      first_loading: true,
      add_gallery: [],
      add_gallery_contacts: [],
      delete_gallery: [],
      delete_gallery_contacts: [],
      delete_models: [],
      project_models: [],
      models_list: [],
      model_selected: "",
      project_model: [],
      errorEditProject: false,
      excel: [],
      infoExcelProperty: [],
      list_crm: [
        "CRM Zona-Soluciones",
        "CRM Brokersland",
        "CRM Patagual",
        "CRM Urban-Corp",
        "CRM Brio",
        "CRM Terraustral",
        "CRM Tres Piedras",
      ],
      atractions: [
        { name_en: "exercise_area", name_es: "Área de ejercicios" },
        { name_en: "green_area", name_es: "Áreas verdes" },
        { name_en: "bikers", name_es: "Bicicleteros" },
        { name_en: "games_accessibility", name_es: "Juegos infantiles" },
        { name_en: "calisthenics_area", name_es: "Área calistenia" },
        { name_en: "stores", name_es: "Locales comerciales" },
        { name_en: "events_room", name_es: "Sala multiuso" },
        { name_en: "clean_points", name_es: "Puntos limpios" },
        { name_en: "elevators", name_es: "Ascensores" },
        { name_en: "controlled_access", name_es: "Acceso controlado" },
        { name_en: "quincho", name_es: "Quincho" },
        { name_en: "swimming_pool", name_es: "Piscina" },
        { name_en: "hospital", name_es: "Cercano a Hospitales" },
        { name_en: "subway_station", name_es: "A pasos de estación de metro" },
      ],
    };
  },
  mounted() {
    //ordenar por campo order
    this.listImages.sort((a, b) => a.order - b.order);
  },
  mixins: [togglePasswordVisibility],
  validations: {
    modalContact: {
      name: { required },
      email: {
        required,
        email,
      },
      phone: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
    },
    editForm: {
      project_name: {
        required,
      },
      sales_room: {},
      property_type: {
        required,
      },
      subsidy_type: {
        required,
      },
      /*project_status_id: {
          required,
        },*/
      real_state_agency: {
        required,
      },
      /*quotation_validity: {
          required,
          numeric
        },*/
      orden: {
        numeric,
      },
      /*reserve_validity: {
          required,
          numeric
        },*/
      price_from: {
        required,
      },
      bedrooms_from: {
        required,
      },
      /*bathrooms_from: {
          required,
        },*/
      area_from: {
        required,
      },
      region: {
        required,
      },
      province: {
        required,
      },
      commune: {
        required,
      },
      address: {
        required,
      },
      latitude: {
        required,
        decimal,
      },
      longitude: {
        required,
        decimal,
      },
      brochure: {
        url,
      },
      nube360: {
        url,
      },
      tour360: {
        url,
      },
      /*reserve_amount: {
          required,
          numeric
        },*/
      /*money_type: {
          required
        },*/
      /*reserve_type: {
          required,
        },*/
      /*promises_type: {
          required,
        },*/
      executive_name_list: {
        required,
      },
      executive_phone_list: {
        required,
      },
      executive_email_list: {
        //required
      },
      email_quotation_list: {
        required,
      },
      /*email_promise_list: {
          requiredIf: requiredIf(function() {
            var filtro = this.editForm.plans_additional.filter(element => element == 2);
            return filtro.length > 0;
          }),
        },*/
      email_booking_list: {
        required,
        /*requiredIf: requiredIf(function() {
            var filtro = this.editForm.plans_additional.filter(element => element == 1);
            return filtro.length > 0;
          }),*/
      },
      /*email_disengagement_list: {
          requiredIf: requiredIf(function() {
            var filtro = this.editForm.plans_additional.filter(element => element == 4);
            return filtro.length > 0;
          }),
        },*/
      chatbot_url: {
        requiredIf: requiredIf(function () {
          var filtro = this.editForm.plans_additional.filter(
            (element) => element == 3
          );
          return filtro.length > 0;
        }),
      },
      miniature: {
        required,
      },
      /*portrait: {
          required
        },*/
      gallery: {
        required,
      },
      crm_email: {
        email,
      },
      plans_additional: {},
      //project_stage:{},
      minimumSalary: {
        numeric,
      },
    },
    region: {
      required,
    },
    province: {
      required,
    },
    executive_name: {
      required,
    },
    executive_phone: {
      required,
      numeric,
      minLength: minLength(9),
      maxLength: maxLength(9),
    },
    executive_email: {
      required,
      email,
    },
    email_quotation: {
      required,
      email,
    },
    /*email_promise: {
        required,
        email
      },*/
    email_booking: {
      required,
      email,
    },
    /*email_disengagement: {
        required,
        email
      }*/
  },
  async beforeMount() {
    var userrole = this.$PermissionHelper.getRole().toLowerCase();
    if (userrole == "admin" || userrole == "superadmin") {
      this.show_admin_input = true;
    }
    this.permissions_visible = this.$PermissionHelper.checkPermissions(
      this.permissions_visible
    );
    await this._setEditForm();
    await this._getEditLocation();
    await this._getModels();
  },
  computed: {
    listImages() {
      return this.editForm.gallery;
    },
    ...mapState("profile", ["locations"]),
    ...mapGetters("profile", [
      "realStateAgencies",
      "projectStatus",
      "subsidyTypes",
      "projects",
      "plansAdditional",
      "projectStage",
    ]),
    passwordToggleIconApiKey() {
      return this.passwordFieldTypeApiKey === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconSecretKey() {
      return this.passwordFieldTypeSecretKey === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    modelPreview() {
      return this.model_selected === null || this.model_selected === ""
        ? "Previsualización modelo"
        : "No hay imagen de este modelo";
    },
    checkModelSource() {
      const modelIndex = (element) => element.model === this.model_selected;
      const index = this.project_models.findIndex(modelIndex);
      return index != -1 ? true : false;
    },
    modelSource() {
      let model = this.project_models.filter((list) => {
        return list.model === this.model_selected;
      });
      return model[0].src;
    },
    miniatureErrors() {
      const errors = [];
      if (!this.$v.editForm.miniature.$dirty) return errors;
      !this.$v.editForm.miniature.required &&
        errors.push("La miniatura es obligatoria");
      return errors;
    },
    /*portraitErrors() {
        const errors = []
        if (!this.$v.editForm.portrait.$dirty) return errors
        !this.$v.editForm.portrait.required && errors.push('La portada es obligatoria')
        return errors
      },*/
    galleryErrors() {
      const errors = [];
      if (!this.$v.editForm.gallery.$dirty) return errors;
      !this.$v.editForm.gallery.required &&
        errors.push("La galería es obligatoria");
      return errors;
    },
    excelErrors() {
      const errors = [];
      if (!this.$v.editForm.excel.$dirty) return errors;
      !this.$v.editForm.excel.required &&
        errors.push("Archivo excel es obligatorio");
      return errors;
    },
    executive_name_listErrors() {
      const errors = [];
      if (!this.$v.editForm.executive_name_list.$dirty) return errors;
      !this.$v.editForm.executive_name_list.required &&
        errors.push("Debes ingresar al menos un nombre");
      return errors;
    },
    executive_phone_listErrors() {
      const errors = [];
      if (!this.$v.editForm.executive_phone_list.$dirty) return errors;
      !this.$v.editForm.executive_phone_list.required &&
        errors.push("Debes ingresar al menos un teléfono");
      return errors;
    },
    executive_email_listErrors() {
      const errors = [];
      if (!this.$v.editForm.executive_email_list.$dirty) return errors;
      !this.$v.editForm.executive_email_list.required &&
        errors.push("Debes ingresar al menos un correo");
      return errors;
    },
    email_query_listErrors() {
      const errors = [];
      if (!this.$v.editForm.email_quotation_list.$dirty) return errors;
      !this.$v.editForm.email_quotation_list.required &&
        errors.push("Debes ingresar un registro de consulta");
      return errors;
    },
    email_quotation_listErrors() {
      const errors = [];
      if (!this.$v.editForm.email_quotation_list.$dirty) return errors;
      !this.$v.editForm.email_quotation_list.required &&
        errors.push("Debes ingresar al menos un correo");
      return errors;
    },
    /*email_promise_listErrors() {
        const errors = []
        if (!this.$v.editForm.email_promise_list.$dirty) return errors
        !this.$v.editForm.email_promise_list.required && errors.push('Debes ingresar al menos un correo')
        return errors
      },*/
    email_booking_listErrors() {
      const errors = [];
      if (!this.$v.editForm.email_booking_list.$dirty) return errors;
      !this.$v.editForm.email_booking_list.required &&
        errors.push("Debes ingresar al menos un correo");
      return errors;
    },
    /*email_disengagement_listErrors() {
        const errors = []
        if (!this.$v.editForm.email_disengagement_list.$dirty) return errors
        !this.$v.editForm.email_disengagement_list.required && errors.push('Debes ingresar al menos un correo')
        return errors
      },*/
    project_nameErrors() {
      const errors = [];
      if (!this.$v.editForm.project_name.$dirty) return errors;
      !this.$v.editForm.project_name.required &&
        errors.push("El nombre es obligatorio");
      return errors;
    },
    property_typeErrors() {
      const errors = [];
      if (!this.$v.editForm.property_type.$dirty) return errors;
      !this.$v.editForm.property_type.required &&
        errors.push("Selecciona un tipo de propiedad");
      return errors;
    },
    subsidy_typeErrors() {
      const errors = [];
      if (!this.$v.editForm.subsidy_type.$dirty) return errors;
      !this.$v.editForm.subsidy_type.required &&
        errors.push("Selecciona un tipo de subsidio");
      return errors;
    },
    /*construction_stageErrors () {
        const errors = []
        if (!this.$v.editForm.project_status_id.$dirty) return errors
        !this.$v.editForm.project_status_id.required && errors.push('Selecciona una etapa')
        return errors
      },*/
    real_state_agencyErrors() {
      const errors = [];
      if (!this.$v.editForm.real_state_agency.$dirty) return errors;
      !this.$v.editForm.real_state_agency.required &&
        errors.push("Selecciona una inmobiliaria");
      return errors;
    },
    /*quotation_validityErrors () {
        const errors = []
        if (!this.$v.editForm.quotation_validity.$dirty) return errors
        !this.$v.editForm.quotation_validity.required && errors.push('La vigencia es obligatoria')
        !this.$v.editForm.quotation_validity.numeric && errors.push('Solo números')
        return errors
      },*/
    /*reserve_validityErrors () {
        const errors = []
        if (!this.$v.editForm.reserve_validity.$dirty) return errors
        !this.$v.editForm.reserve_validity.required && errors.push('La vigencia es obligatoria')
        !this.$v.editForm.reserve_validity.numeric && errors.push('Solo números')
        return errors
      },*/
    price_fromErrors() {
      const errors = [];
      if (!this.$v.editForm.price_from.$dirty) return errors;
      !this.$v.editForm.price_from.required &&
        errors.push("El precio es obligatorio");
      return errors;
    },
    bedrooms_fromErrors() {
      const errors = [];
      if (!this.$v.editForm.bedrooms_from.$dirty) return errors;
      !this.$v.editForm.bedrooms_from.required &&
        errors.push("Los dormitorios son obligatorios");
      return errors;
    },
    /*bathrooms_fromErrors () {
        const errors = []
        if (!this.$v.editForm.bathrooms_from.$dirty) return errors
        !this.$v.editForm.bathrooms_from.required && errors.push('Los baños son obligatorios')
        return errors
      },*/
    area_fromErrors() {
      const errors = [];
      if (!this.$v.editForm.area_from.$dirty) return errors;
      !this.$v.editForm.area_from.required &&
        errors.push("El área es obligatoria");
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.editForm.region.$dirty) return errors;
      !this.$v.editForm.region.required &&
        errors.push("La región es obligatoria");
      return errors;
    },
    provinceErrors() {
      const errors = [];
      if (!this.$v.editForm.province.$dirty) return errors;
      !this.$v.editForm.province.required &&
        errors.push("La provincia es obligatoria");
      return errors;
    },
    communeErrors() {
      const errors = [];
      if (!this.$v.editForm.commune.$dirty) return errors;
      !this.$v.editForm.commune.required &&
        errors.push("La comuna es obligatoria");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.editForm.address.$dirty) return errors;
      !this.$v.editForm.address.required &&
        errors.push("La dirección es obligatoria");
      return errors;
    },
    latitudeErrors() {
      const errors = [];
      if (!this.$v.editForm.latitude.$dirty) return errors;
      !this.$v.editForm.latitude.required && errors.push("Campo obligatorio");
      !this.$v.editForm.latitude.decimal &&
        errors.push("Solo valores numéricos");
      return errors;
    },
    longitudeErrors() {
      const errors = [];
      if (!this.$v.editForm.longitude.$dirty) return errors;
      !this.$v.editForm.longitude.required && errors.push("Campo obligatorio");
      !this.$v.editForm.longitude.decimal &&
        errors.push("Solo valores numéricos");
      return errors;
    },
    executive_nameErrors() {
      const errors = [];
      if (!this.$v.executive_name.$dirty) return errors;
      !this.$v.executive_name.required && errors.push("Ingresa un nombre");
      return errors;
    },
    executive_phoneErrors() {
      const errors = [];
      if (!this.$v.executive_phone.$dirty) return errors;
      !this.$v.executive_phone.required && errors.push("Ingresa un teléfono");
      !this.$v.executive_phone.numeric && errors.push("Solo números");
      !this.$v.executive_phone.minLength &&
        errors.push("Debes ingresar 9 dígitos");
      !this.$v.executive_phone.maxLength &&
        errors.push("Debes ingresar 9 dígitos");
      return errors;
    },
    executive_emailErrors() {
      const errors = [];
      if (!this.$v.executive_email.$dirty) return errors;
      !this.$v.executive_email.required && errors.push("Ingresa un email");
      !this.$v.executive_email.email &&
        errors.push("Formato de email incorrecto");
      return errors;
    },
    email_queryErrors() {
      const errors = [];
      if (!this.$v.email_query.$dirty) return errors;
      !this.$v.email_query.required && errors.push("Ingresa un email");
      !this.$v.email_query.email && errors.push("Formato de email incorrecto");
      return errors;
    },
    email_quotationErrors() {
      const errors = [];
      if (!this.$v.email_quotation.$dirty) return errors;
      !this.$v.email_quotation.required && errors.push("Ingresa un email");
      !this.$v.email_quotation.email &&
        errors.push("Formato de email incorrecto");
      return errors;
    },
    /*email_promiseErrors () {
        const errors = []
        if (!this.$v.email_promise.$dirty) return errors
        !this.$v.email_promise.required && errors.push('Ingresa un email')
        !this.$v.email_promise.email && errors.push('Formato de email incorrecto')
        return errors
      },*/
    email_bookingErrors() {
      const errors = [];
      if (!this.$v.email_booking.$dirty) return errors;
      !this.$v.email_booking.required && errors.push("Ingresa un email");
      !this.$v.email_booking.email &&
        errors.push("Formato de email incorrecto");
      return errors;
    },
    /*email_disengagementErrors () {
        const errors = []
        if (!this.$v.email_disengagement.$dirty) return errors
        !this.$v.email_disengagement.required && errors.push('Ingresa un email')
        !this.$v.email_disengagement.email && errors.push('Formato de email incorrecto')
        return errors
      },*/
    brochureErrors() {
      const errors = [];
      if (!this.$v.editForm.brochure.$dirty) return errors;
      // !this.$v.editForm.brochure.required && errors.push('La URL es obligatoria')
      !this.$v.editForm.brochure.url &&
        errors.push("Debes ingresar una URL válida");
      return errors;
    },
    nube360Errors() {
      const errors = [];
      if (!this.$v.editForm.nube360.$dirty) return errors;
      // !this.$v.editForm.nube360.required && errors.push('La URL es obligatoria')
      !this.$v.editForm.nube360.url &&
        errors.push("Debes ingresar una URL válida");
      return errors;
    },
    tour360Errors() {
      const errors = [];
      if (!this.$v.editForm.tour360.$dirty) return errors;
      // !this.$v.editForm.tour360.required && errors.push('La URL es obligatoria')
      !this.$v.editForm.tour360.url &&
        errors.push("Debes ingresar una URL válida");
      return errors;
    },
    /*reserve_amountErrors () {
        const errors = []
        if (!this.$v.editForm.reserve_amount.$dirty) return errors
        !this.$v.editForm.reserve_amount.required && errors.push('El monto es obligatorio')
        !this.$v.editForm.reserve_amount.numeric && errors.push('Solo números')
        return errors
      },*/
    /*reserve_typeErrors () {
        const errors = []
        if (!this.$v.editForm.reserve_type.$dirty) return errors
        !this.$v.editForm.reserve_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    /*promises_typeErrors () {
        const errors = []
        if (!this.$v.editForm.promises_type.$dirty) return errors
        !this.$v.editForm.promises_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    /*money_typeErrors () {
        const errors = []
        if (!this.$v.editForm.money_type.$dirty) return errors
        !this.$v.editForm.money_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    ordenErrors() {
      const errors = [];
      if (!this.$v.editForm.orden.$dirty) return errors;
      !this.$v.editForm.orden.numeric &&
        errors.push("Formato de orden incorrecto");
      return errors;
    },
    crm_email_listErrors() {
      const errors = [];
      if (!this.$v.editForm.crm_email.$dirty) return errors;
      !this.$v.editForm.crm_email.email &&
        errors.push("Debes ingresar un correo valido");
      return errors;
    },
    chatbotURLErrors() {
      const errors = [];
      if (!this.$v.editForm.chatbot_url.$dirty) return errors;
      //!this.$v.editForm.chatbot_url.required && errors.push('La URL es obligatoria')
      return errors;
    },
  },
  methods: {
    startDrag2(evt, index) {
      this.draggedIndex = index;
      evt.dataTransfer.effectAllowed = 'move';
    },
    onDragOver2(evt, index) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'move';
    },
    onDrop2(evt, index) {
      evt.preventDefault();
      const draggedItem = this.listImages[this.draggedIndex];
      this.listImages.splice(this.draggedIndex, 1);
      this.listImages.splice(index, 0, draggedItem);
      this.draggedIndex = null;
    },
    ...mapActions("projects", [
      "editProjects",
      "exportDemoProperties",
      "checkPropertiesExcel",
      "savePropertiesExcel",
      "addProjectImage",
      "addProjectImageContact",
      "removeProjectImage",
      "modelsByProject",
      "editMediaPropertyByModel",
      "deleteMediaProject",
      "editOrderProjectImages",
      "fetchMediaProjects",
    ]),
    ready() {
      gmaps().then((maps) => {
        this.autocomplete = new maps.places.Autocomplete(
          this.$refs.autocomplete
        );
        this.autocomplete.addListener("place_changed", this.updateAdress);
      });
    },
    updateAdress() {
      const place = this.autocomplete.getPlace();
      // Set end point to selected address
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        this.editForm.address = place.name;

        this.mapOptions.center = { lat, lng };
        this.mapOptions.zoom = 15;
        this.markerOptions.position = { lat, lng };

        this.updatePosition({ lat, lng });
      }
    },
    async _exportDemoProperties() {
      await this.exportDemoProperties().catch((err) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No se ha podido descargar el archivo",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },
    _previewFileExcel(event) {
      if (
        event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.createForm.excel = event.target.files[0];
        this.excel = event.target.files[0];
        this._checkPropertiesExcel();
      } else {
        this.createForm.excel = [];
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formato de archivo incorrecto",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    async _checkPropertiesExcel() {
      this.infoExcelProperty = [];
      await this.checkPropertiesExcel(this.excel)
        .then((res) => {
          if (!res.data.checkPropertiesExcel.status) {
            let dataId = [],
              dataMsg = [];
            dataId = JSON.parse(res.data.checkPropertiesExcel.data);
            dataMsg = JSON.parse(res.data.checkPropertiesExcel.info);
            for (let x = 0; x < dataMsg.length; x++) {
              let identifier = "";
              let messages = [];
              let empty = true;
              identifier = dataId[x].Identificador;
              dataMsg[x].forEach((msg) => {
                if (msg != "") {
                  messages.push(msg);
                  empty = false;
                }
              });
              this.infoExcelProperty.push({
                identifier: identifier,
                messages: messages,
                empty: empty,
              });
            }
            this.$bvModal.show("excel-edit-modal");
            this.excel = [];
            this.createForm.excel = [];

            this.validJsonExcel = false;
          } else {
            this.jsonExcel = res.data.checkPropertiesExcel.data.replace(
              /"/g,
              "|"
            );
            this.validJsonExcel = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Se ha cargado el excel con éxito",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ha ocurrido un error al verificar el Excel",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    modalClosed() {
      this.modalContact = {
        name: "",
        phone: "",
        email: "",
        image: "",
        isnew: "1",
      };
      this.gallery_contacts_modal = [];
      this.$v.modalContact.$reset();
    },
    addContactOrder() {
      this.modalContact = {
        name: "",
        phone: "",
        email: "",
        image: undefined,
        realIndex: "-1",
        isnew: "1",
      };
      this.$refs["modalContacts"].show();
    },
    saveContactOrder() {
      let nombres = this.editForm.executive_name_list.filter(
        (word) => word == this.modalContact.name
      );
      if (nombres.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No puedes agregar el mismo nombre",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      let phones = this.editForm.executive_phone_list.filter(
        (word) => word == this.modalContact.phone
      );
      if (phones.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No puedes agregar el mismo telefono",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      let emails = this.editForm.executive_email_list.filter(
        (word) => word == this.modalContact.email
      );
      if (emails.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No puedes agregar el mismo email",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      this.modalContact.isnew = "0";
      this.modalContact.realIndex = this.editForm.executive_email_list.length;
      this.contacts_orders.push(JSON.parse(JSON.stringify(this.modalContact)));
      this.editForm.executive_name_list.push(this.modalContact.name);
      this.editForm.executive_phone_list.push(this.modalContact.phone);
      this.editForm.executive_email_list.push(this.modalContact.email);
      if (this.modalContact.image != "") {
        this.editForm.gallery_contacts.push(this.modalContact.image);
      }
      this.gallery_contacts_modal = [];
      this.modalContact = {
        name: "",
        phone: "",
        email: "",
        image: "",
        realIndex: "-1",
        isnew: "1",
      };
      this.$refs["modalContacts"].hide();
    },
    editContactOrderModal() {
      var indexElemento = this.modalContact.arrayIndex;

      var executive_name_withoutme = JSON.parse(
        JSON.stringify(this.editForm.executive_name_list)
      );
      executive_name_withoutme.splice(indexElemento, 1);

      var executive_email_list_withoutme = JSON.parse(
        JSON.stringify(this.editForm.executive_email_list)
      );
      executive_email_list_withoutme.splice(indexElemento, 1);

      var executive_phone_list_withoutme = JSON.parse(
        JSON.stringify(this.editForm.executive_phone_list)
      );
      executive_phone_list_withoutme.splice(indexElemento, 1);

      let nombres = executive_name_withoutme.filter(
        (word) => word == this.modalContact.name
      );
      if (nombres.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya existe un contacto con el mismo nombre",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      let phones = executive_phone_list_withoutme.filter(
        (word, index) =>
          word == this.modalContact.phone &&
          index != this.modalContact.realIndex
      );
      if (phones.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya existe un contacto con el mismo telefono",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      let emails = executive_email_list_withoutme.filter(
        (word) => word == this.modalContact.email
      );
      if (emails.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya existe un contacto con el mismo email",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      this.contacts_orders[indexElemento] = JSON.parse(
        JSON.stringify(this.modalContact)
      );
      this.editForm.executive_name_list[indexElemento] = this.modalContact.name;
      this.editForm.executive_phone_list[indexElemento] =
        this.modalContact.phone;
      this.editForm.executive_email_list[indexElemento] =
        this.modalContact.email;
      this.editForm.gallery_contacts[indexElemento] = this.modalContact.image;

      this.$refs["modalContacts"].hide();
    },
    showModalEdit(editContactOrder, index) {
      this.modalContact = {
        name: editContactOrder.name,
        phone: editContactOrder.phone,
        email: editContactOrder.email,
        image: editContactOrder.image,
        realIndex: editContactOrder.realIndex,
        arrayIndex: index,
        isnew: editContactOrder.isnew,
      };
      this.$v.modalContact.$touch();
      this.gallery_contacts_modal = [this.contacts_orders[index].image];
      this.$refs["modalContacts"].show();
    },
    deleteContactOrder(editContactOrder, index) {
      this.$swal({
        title: "¿Estás seguro que deseas eliminar este contacto?",
        text: "La siguiente acción eliminará el contacto.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.realDeleteContactOrder(editContactOrder, index);
        }
      });
    },
    realDeleteContactOrder(editContactOrder, index) {
      this._deleteGalleryContactModal();
      this.contacts_orders.splice(index, 1);
      this.editForm.executive_name_list.splice(index, 1);
      this.editForm.executive_phone_list.splice(index, 1);
      this.editForm.executive_email_list.splice(index, 1);
    },
    togglePasswordVisibilityApiKey() {
      if (this.passwordFieldTypeApiKey === "password") {
        this.passwordFieldTypeApiKey = "text";
      } else {
        this.passwordFieldTypeApiKey = "password";
      }
    },
    togglePasswordVisibilitySecretKey() {
      if (this.passwordFieldTypeSecretKey === "password") {
        this.passwordFieldTypeSecretKey = "text";
      } else {
        this.passwordFieldTypeSecretKey = "password";
      }
    },
    async _exportDemoProperties() {
      await this.exportDemoProperties().catch((err) => {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Problemas al exportar los datos",
        });
      });
    },
    _deleteMiniature() {
      this.editForm.miniature = "";
    },
    checkRegion() {
      this.editForm.province = "";
      this.editForm.commune = "";
      if (this.editForm.region != "" && this.editForm.region != null) {
        this.provinces_list = this.locations.filter((locations) => {
          return locations.id === this.editForm.region;
        });
        this.provinces_list = this.provinces_list[0].provinces;
      }
    },
    checkProvince() {
      this.editForm.commune = "";
      if (this.editForm.province != "" && this.editForm.province != null) {
        this.communes_list = this.provinces_list.filter((provinces) => {
          return provinces.id === this.editForm.province;
        });
        this.communes_list = this.communes_list[0].communes;
      }
    },
    maxProjects(e) {
      if (e.length > 3) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Puedes seleccionar hasta 3 proyectos",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        e.pop();
      }
    },
    _previewFileMiniature(event) {
      if (event.target.files[0] != null) {
        if (
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/jpg" ||
          event.target.files[0].type === "image/png"
        ) {
          this.miniature = [];
          this.editForm.miniature = {
            file: event.target.files[0],
            src: URL.createObjectURL(event.target.files[0]),
            new: true,
          };
        } else {
          this.editForm.miniature = "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Formato de archivo incorrecto",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      } else {
        this.editForm.miniature = "";
        this.miniature = [];
      }
    },
    /*_deletePortrait() {
        this.editForm.portrait = ''
      },*/
    /*_previewFilePortrait(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.portrait = []
            this.editForm.portrait = {file: event.target.files[0], src: URL.createObjectURL(event.target.files[0]), new: true}
          }else{
            this.editForm.portrait = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
          this.editForm.portrait = ''
          this.portrait = []
        }
      },*/
    _deleteGallery(position, img) {
      if (!img.new) {
        this.delete_gallery.push(img.media_id);
      }
      this.editForm.gallery.splice(position, 1);
    },
    _deleteGalleryContact(position, img) {
      if (!img.new) {
        this.delete_gallery_contacts.push(img.media_id);
      }
      this.editForm.gallery_contacts.splice(position, 1);
    },
    _deleteGalleryContactModal() {
      if (this.modalContact.image)
        if (!this.modalContact.image.new) {
          if (this.modalContact.image.media_id) {
            this.delete_gallery_contacts.push(this.modalContact.image.media_id);
          }
        }
      this.modalContact.image = undefined;
    },
    _previewFileGalleryContactModal(event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === "image/jpeg" ||
            event.target.files[index].type === "image/jpg" ||
            event.target.files[index].type === "image/png"
          ) {
            let fileObj = {
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true,
            };
            this.gallery_contacts_modal = [fileObj];
            this.modalContact.image = {
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true,
              full_url: fileObj.src,
              new: true,
            };
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Formato de archivo incorrecto",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        }
      }
    },
    _previewFileGalleryContact(event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === "image/jpeg" ||
            event.target.files[index].type === "image/jpg" ||
            event.target.files[index].type === "image/png"
          ) {
            this.editForm.gallery_contacts.push({
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true,
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Formato de archivo incorrecto",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        }
        this.gallery_contacts = [];
      } else {
        this.gallery_contacts = [];
        this.editForm.gallery_contacts = [];
      }
    },
    _previewFileGallery(event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === "image/jpeg" ||
            event.target.files[index].type === "image/jpg" ||
            event.target.files[index].type === "image/png"
          ) {
            this.editForm.gallery.push({
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true,
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Formato de archivo incorrecto",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        }
        this.gallery = [];
      } else {
        this.gallery = [];
        this.editForm.gallery = "";
      }
    },
    _deleteExcel() {
      this.editForm.excel = [];
    },
    _previewFileExcel(event) {
      if (
        event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.editForm.excel = event.target.files[0];
        this.excel = event.target.files[0];
        this._checkPropertiesExcel();
      } else {
        this.editForm.excel = [];
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formato de archivo incorrecto",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    _editProjects() {
      this.$v.editForm.$touch();
      if (!this.$v.editForm.$invalid) {
        this.editProjects(this.editForm)
          .then((res) => {
            this._saveContactMedias().then((resMedia) => {
              if (this.validJsonExcel) {
                let dataProperties = {
                  project_id: this.project.id,
                  jsonExcel: this.jsonExcel,
                };
                this.savePropertiesExcel(dataProperties)
                  .then((resProperties) => {
                    this.finishEditionAction();
                  })
                  .catch((err) => {
                    this.overlayForm = false;
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "El proyecto no ha podido ser editado",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                      },
                    });
                  });
              } else {
                this.finishEditionAction();
              }
            });
          })
          .catch((err) => {
            console.log(err);
            this.overlayForm = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "El proyecto no ha podido ser editado",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.overlayForm = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campos con errores o sin rellenar",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    finishEditionAction() {
      this.overlayForm = false;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "El proyecto ha sido editado con éxito",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
      this.editForm = {
        type: "",
        project_name: "",
        sales_room: "",
        orden: "",
        property_type: "",
        subsidy_type: "",
        construction_stage: "",
        real_state_agency: "",
        //projects_related: [],
        //quotation_validity: '',
        //reserve_validity: '',
        crm_id: "",
        crm_zp: "",
        crm_email: "",
        price_from: "",
        bedrooms_from: "",
        //bathrooms_from: '',
        area_from: "",
        //deliver_date: '',
        project_description: "",
        api_key: "",
        secret_key: "",
        //money_type: '',
        //reserve_amount: '',
        //reserve_type: '',
        //promises_type: '',
        address: "",
        region: "",
        province: "",
        commune: "",
        latitude: "",
        length: "",
        //legal_quote_text: '',
        executive_name_list: [],
        executive_phone_list: [],
        executive_email_list: [],
        email_query_list: [],
        email_quotation_list: [],
        //email_promise_list:[],
        email_booking_list: [],
        //email_disengagement_list:[],
        brochure: "",
        nube360: "",
        tour360: "",
        video_youtube_id: "",
        miniature: [],
        //portrait: [],
        gallery: [],
        gallery_contacts: [],
        excel: [],
        sell_subsidies: 0,
        chatbot_url: "",
        integration_crm: "",
        promotions: "",
        minimumSalary: null,
      };
      //this.portrait = [],
      (this.gallery = []),
        (this.gallery_contacts = []),
        this.$v.editForm.$reset();
      this.$router.push({ name: "projects" });
    },
    async _saveMedias() {
      this.overlayForm = true;
      this.errorEditProject = false;
      if (this.editForm.miniature.new) {
        await this.addProjectImage({
          project_id: this.editForm.id,
          type: "logo",
          file: this.editForm.miniature.file,
        }).catch((err) => {
          this.errorEditProject = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al guardar miniatura",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          }).finally(() => {
            this.overlayForm = false;
          });
        });
      }

      this.listImages.forEach((item, index) => {
        item.order = index + 1;
      });

      let gallery = this.editForm.gallery.filter((media) => {
        return media.new === true;
      });

      if (gallery.length > 0) {
        gallery.forEach((element) => {
          this.add_gallery.push(element.file);
        });
        await this.addProjectImage({
          project_id: this.editForm.id,
          type: "gallery_photos",
          file: this.add_gallery,
        })
          .then(async (res) => {
            await this.fetchMediaProjects({
              project_id: this.editForm.id
            })
              .then((data) => {
                let gallery = data.projectsmedia.filter((media) => {
                  return media.mediatype.id === 5;
                });
                this.editForm.gallery = [];
                gallery.forEach((image) => {
                  this.editForm.gallery.push({
                    src: image.full_url,
                    new: false,
                    media_id: image.id,
                    order: image.order,
                  });
                });
              })
              .catch((err) => {
                this.errorEditProject = true;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error al guardar galería",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.overlayForm = false;
              });
          })
          .catch((err) => {
            this.errorEditProject = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error al guardar galería",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          })
          .finally(async () => {
            this.overlayForm = false;
          });
      }

      if (this.delete_gallery.length > 0) {
        this.delete_gallery.forEach((element) => {
          this.removeProjectImage({
            project_id: this.editForm.id,
            media_id: element,
          })
            .catch((err) => {
              this.errorEditProject = true;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error al guardar galería",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.overlayForm = false;
            });
        });
      }

      const orderImages = this.listImages.map((item) => {
        return {
          id: item.media_id,
          order: item.order,
        };
      });
      await this.editOrderProjectImages({
        images: orderImages
      })
        .catch((err) => {
          this.errorEditProject = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al guardar galería",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.overlayForm = false;
        });

      if (!this.errorEditProject) {
        this._saveModels();
      }
    },
    async _saveContactMedias() {
      //nuevas imagenes de contacto
      if (this.delete_gallery_contacts.length > 0) {
        this.delete_gallery_contacts.forEach((element) => {
          this.removeProjectImage({
            project_id: this.editForm.id,
            media_id: element,
          })
            .catch((err) => {
              this.errorEditProject = true;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error al guardar galería",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.overlayForm = false;
            });
        });
      }
      let indexs = [];
      this.contacts_orders.forEach((element) => {
        if (element.image != "" && element.image != null) {
          indexs.push(element.image.new === true ? "1" : "0");
        } else {
          indexs.push("0");
        }
      });

      let new_images = this.editForm.gallery_contacts.filter((media) => {
        return media != undefined;
      });
      new_images = new_images.filter((media) => {
        return media.new === true;
      });
      if (new_images.length > 0) {
        let new_images_obj = [];
        new_images.forEach((element) => {
          new_images_obj.push(element.file);
        });

        var args = {
          project_id: this.editForm.id,
          type: "gallery_contact_photos",
          file: new_images_obj,
          indexs: indexs,
        };

        await this.addProjectImageContact({
          project_id: this.editForm.id,
          type: "gallery_contact_photos",
          file: new_images_obj,
          indexs: indexs,
        })
          .catch((err) => {
            this.errorEditProject = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error al guardar imagen contacto",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.overlayForm = false;
          });
      }
    },
    async _saveModels() {
      await this.project_models.forEach((element) => {
        if (element.new) {
          this.editMediaPropertyByModel({
            project_id: this.project.id,
            models: element.model,
            file: element.file,
          })
            .catch((err) => {
              this.errorEditProject = true;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error al guardar modelo " + element.model,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.overlayForm = false;
            });
        }
      });
      if (!this.errorEditProject) {
        this._editProjects();
      }
    },
    _removeExecutive(item, type) {
      if (type === "name") {
        this.editForm.executive_name_list.splice(
          this.editForm.executive_name_list.indexOf(item),
          1
        );
      }
      if (type === "phone") {
        this.editForm.executive_phone_list.splice(
          this.editForm.executive_phone_list.indexOf(item),
          1
        );
      }
      if (type === "email") {
        this.editForm.executive_email_list.splice(
          this.editForm.executive_email_list.indexOf(item),
          1
        );
      }
    },
    _addExecutive(type) {
      if (type === "name" && !this.$v.executive_name.$error) {
        if (this.executive_name === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa un nombre",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          let exist = false;
          for (
            let index = 0;
            index < this.editForm.executive_name_list.length;
            index++
          ) {
            if (
              this.executive_name === this.editForm.executive_name_list[index]
            ) {
              exist = true;
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No puedes agregar el mismo nombre",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.editForm.executive_name_list.push(this.executive_name);
            this.executive_name = "";
            this.$v.executive_name.$reset();
          }
        }
      }
      if (type === "phone" && !this.$v.executive_phone.$error) {
        if (this.executive_phone === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa un teléefono",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          let exist = false;
          for (
            let index = 0;
            index < this.editForm.executive_phone_list.length;
            index++
          ) {
            if (
              this.executive_phone === this.editForm.executive_phone_list[index]
            ) {
              exist = true;
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No puedes agregar el mismo teléfono",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.editForm.executive_phone_list.push(this.executive_phone);
            this.executive_phone = "";
            this.$v.executive_phone.$reset();
          }
        }
      }
      if (type === "email" && !this.$v.executive_email.$error) {
        if (this.executive_email === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa un correo",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          let exist = false;
          for (
            let index = 0;
            index < this.editForm.executive_email_list.length;
            index++
          ) {
            if (
              this.executive_email === this.editForm.executive_email_list[index]
            ) {
              exist = true;
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No puedes agregar el mismo correo",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.editForm.executive_email_list.push(this.executive_email);
            this.executive_email = "";
            this.$v.executive_email.$reset();
          }
        }
      }
    },
    _removeEmail(item, type) {
      if (type === "query") {
        this.editForm.email_query_list.splice(
          this.editForm.email_query_list.indexOf(item),
          1
        );
      }
      /*if(type === "promise"){
                    this.editForm.email_promise_list.splice(this.editForm.email_promise_list.indexOf(item), 1)
                }*/
      if (type === "booking") {
        this.editForm.email_booking_list.splice(
          this.editForm.email_booking_list.indexOf(item),
          1
        );
      }
      /*if(type === "disengagement"){
                    this.editForm.email_disengagement_list.splice(this.editForm.email_disengagement_list.indexOf(item), 1)
                }*/
      if (type === "quotation") {
        this.editForm.email_quotation_list.splice(
          this.editForm.email_quotation_list.indexOf(item),
          1
        );
      }
    },
    _addEmail(type) {
      if (type === "query" && !this.$v.email_query.$error) {
        if (
          this.email_query === "" ||
          this.name_query === "" ||
          this.phone_query === ""
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa los datos de consulta",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          if (this.editForm.email_query_list.length > 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Límite de registros alcanzado",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
            this.name_query = "";
            this.phone_query = "";
            this.email_query = "";
            this.$v.name_query.$reset();
            this.$v.phone_query.$reset();
            this.$v.email_query.$reset();
          } else {
            this.editForm.email_query_list.push({
              name: this.name_query,
              phone: this.phone_query,
              email: this.email_query,
            });
            this.name_query = "";
            this.phone_query = "";
            this.email_query = "";
            this.$v.name_query.$reset();
            this.$v.phone_query.$reset();
            this.$v.email_query.$reset();
          }
        }
      }
      /*if(type === "promise" && !this.$v.email_promise.$error){
                    if(this.email_promise === ""){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Ingresa un correo',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            },
                        })
                    }else{
                        let exist = false
                        for (let index = 0; index < this.editForm.email_promise_list.length; index++) {
                            if(this.email_promise === this.editForm.email_promise_list[index]){
                                exist = true
                            }
                        }
                        if(exist){
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'No puedes agregar el mismo correo',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                },
                            })
                        }else{
                            this.editForm.email_promise_list.push(this.email_promise)
                            this.email_promise = ""
                            this.$v.email_promise.$reset()
                        }
                    }
                }*/
      if (type === "booking" && !this.$v.email_booking.$error) {
        if (this.email_booking === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa un correo",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          let exist = false;
          for (
            let index = 0;
            index < this.editForm.email_booking_list.length;
            index++
          ) {
            if (
              this.email_booking === this.editForm.email_booking_list[index]
            ) {
              exist = true;
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No puedes agregar el mismo correo",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.editForm.email_booking_list.push(this.email_booking);
            this.email_booking = "";
            this.$v.email_booking.$reset();
          }
        }
      }
      /*if(type === "disengagement" && !this.$v.email_disengagement.$error){
                    if(this.email_disengagement === ""){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Ingresa un correo',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            },
                        })
                    }else{
                        let exist = false
                        for (let index = 0; index < this.editForm.email_disengagement_list.length; index++) {
                            if(this.email_disengagement === this.editForm.email_disengagement_list[index]){
                                exist = true
                            }
                        }
                        if(exist){
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'No puedes agregar el mismo correo',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                },
                            })
                        }else{
                            this.editForm.email_disengagement_list.push(this.email_disengagement)
                            this.email_disengagement = ""
                            this.$v.email_disengagement.$reset()
                        }
                    }
                }*/
      if (type === "quotation" && !this.$v.email_quotation.$error) {
        if (this.email_quotation === "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ingresa un correo",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        } else {
          let exist = false;
          for (
            let index = 0;
            index < this.editForm.email_quotation_list.length;
            index++
          ) {
            if (
              this.email_quotation === this.editForm.email_quotation_list[index]
            ) {
              exist = true;
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No puedes agregar el mismo correo",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          } else {
            this.editForm.email_quotation_list.push(this.email_quotation);
            this.email_quotation = "";
            this.$v.email_quotation.$reset();
          }
        }
      }
    },
    addMarker($event) {
      if (this.markers.length === 0) {
        const position = $event.latLng.toJSON();
        this.editForm.latitude = position.lat;
        this.editForm.longitude = position.lng;
        this.mapOptions.center = position;
        this.markers.push({ position });
      } else {
        this.removeMarker();
      }
    },
    removeMarker() {
      this.markers = [];
    },
    updatePosition(position) {
      this.editForm.latitude = position.lat;
      this.editForm.longitude = position.lng;
    },
    isRutValid(rut) {
      return rutHelpers.rutValidate(rut);
    },
    _setEditForm() {
      let gallery, gallery_contacts;
      const index = this.projects.findIndex(
        (project) => project.id === this.project.id
      );
      if (index > -1) {
        this.projects.splice(index, 1);
      }
      this.editForm.id = this.project.id;
      this.editForm.type = this.project.type;
      this.editForm.project_name = this.project.name;
      this.editForm.sales_room = this.project.sales_room;
      this.editForm.orden = this.project.orden;
      this.editForm.property_type = this.project.type_property;
      this.editForm.subsidy_type = this.project.subsidies.map(
        (data) => data.subsidy.id
      );
      //this.editForm.project_status_id = this.project.projectstatus.id
      this.editForm.real_state_agency = this.project.realstateagency.id;
      //this.editForm.projects_related = this.project.projectrelated.map(data => data.project.id)
      //this.editForm.quotation_validity = this.project.quotation_validity
      //this.editForm.reserve_validity = this.project.reserve_validity
      this.editForm.project_description = this.project.description;
      this.editForm.crm_id = this.project.crm_id;
      this.editForm.crm_zp = this.project.crm_zp;
      this.editForm.crm_email = this.project.crm_email;
      this.editForm.price_from = this.project.price_from;
      this.editForm.bedrooms_from = this.project.bedrooms_from;
      //this.editForm.bathrooms_from = this.project.bathrooms_from
      this.editForm.area_from = this.project.area_from;
      //this.editForm.deliver_date = this.project.deliver_date
      this.editForm.address = this.project.address;
      this.editForm.latitude = this.project.latitude;
      this.editForm.longitude = this.project.longitude;
      //this.editForm.legal_quote_text = this.project.legal_quote_text
      this.editForm.api_key =
        this.project.projectpaymentkeys != null
          ? this.project.projectpaymentkeys.api_key
          : "";
      this.editForm.secret_key =
        this.project.projectpaymentkeys != null
          ? this.project.projectpaymentkeys.secret_key
          : "";
      //this.editForm.money_type = this.project.reserve_currency
      //this.editForm.reserve_amount = this.project.reserve_amount
      //this.editForm.reserve_type = this.project.reserve_type
      //this.editForm.promises_type = this.project.promises_type
      //this.editForm.summary = this.project.summary
      this.editForm.brochure =
        this.project.brochure != null ? this.project.brochure.full_url : "";
      this.editForm.nube360 =
        this.project.nube360 != null ? this.project.nube360.full_url : "";
      this.editForm.tour360 =
        this.project.tour360 != null ? this.project.tour360.full_url : "";
      this.editForm.executive_name_list = this.project.executivename.map(
        (data) => data.email
      );
      this.editForm.executive_phone_list = this.project.executivephone.map(
        (data) => data.email
      );
      this.editForm.executive_email_list = this.project.executiveemail.map(
        (data) => data.email
      );
      this.editForm.email_quotation_list =
        this.project.projectcontactsquotation.map((data) => data.email);
      //this.editForm.email_promise_list = this.project.projectcontactspromise.map(data => data.email)
      this.editForm.email_booking_list =
        this.project.projectcontactsbooking.map((data) => data.email);
      //this.editForm.email_disengagement_list = this.project.projectcontactsdisengagement.map(data => data.email)
      this.editForm.miniature = this.project.medias.filter((media) => {
        return media.mediatype.id === 3;
      });
      this.editForm.miniature = {
        src: this.editForm.miniature[0].full_url,
        new: false,
      };
      //this.editForm.portrait = this.project.medias.filter(media => {return media.mediatype.id === 4})
      //this.editForm.portrait = {src: this.editForm.portrait[0].full_url, new: false}
      this.editForm.sell_subsidies = this.project.projectrestriction
        ? this.project.projectrestriction.sell_subsidies
        : 0;
      //this.editForm.project_stage = this.project.projectstage ? this.project.projectstage.id : 0;
      this.editForm.chatbot_url = this.project.chatbot_url;
      this.editForm.exercise_area = this.project.exercise_area;
      this.editForm.green_area = this.project.green_area;
      this.editForm.bikers = this.project.bikers;
      this.editForm.games_accessibility = this.project.games_accessibility;
      this.editForm.calisthenics_area = this.project.calisthenics_area;
      this.editForm.stores = this.project.stores;
      this.editForm.events_room = this.project.events_room;
      this.editForm.clean_points = this.project.clean_points;
      this.editForm.elevators = this.project.elevators;
      this.editForm.controlled_access = this.project.controlled_access;
      this.editForm.quincho = this.project.quincho;
      this.editForm.swimming_pool = this.project.swimming_pool;
      this.editForm.reserve_crm = this.project.reserve_crm;
      this.editForm.reserve_crm_zp = this.project.reserve_crm_zp;
      this.editForm.show_logo_realstate = this.project.show_logo_realstate;
      this.editForm.hospital = this.project.hospital;
      this.editForm.subway_station = this.project.subway_station;
      this.editForm.show_section_news = this.project.show_section_news;
      this.editForm.integration_crm = this.project.integration_crm;
      this.editForm.promotions = this.project.promotions;
      this.editForm.minimumSalary = this.project.renta_minima;
      this.editForm.video_youtube_id = this.project.video_youtube_id;
      let planes = [];
      let planes_id = [];
      this.project.projectadditional.map((element) =>
        planes.push(element.plansadditional)
      );
      planes.map((element) => planes_id.push(element.id));
      this.editForm.plans_additional = planes_id;

      gallery = this.project.medias.filter((media) => {
        return media.mediatype.id === 5;
      });
      gallery.forEach((image) => {
        this.editForm.gallery.push({
          src: image.full_url,
          new: false,
          media_id: image.id,
          order: image.order,
        });
      });

      var contacts_array = [];
      this.project.executiveemail.map((currentValue, index) =>
        contacts_array.push({
          name: "",
          phone: "",
          email: currentValue.email,
          image: currentValue.contactphoto,
          isnew: "0",
          realIndex: index,
        })
      );
      contacts_array.forEach((element) => {
        if (element.image != null) {
          element.image.media_id = element.image.id;
          element.image.new = false;
        }
      });
      this.editForm.executive_phone_list.map(
        (currentValue, index) =>
          contacts_array.length > 0 &&
          (contacts_array[index].phone = currentValue)
      );
      this.editForm.executive_name_list.map(
        (currentValue, index) =>
          contacts_array.length > 0 &&
          (contacts_array[index].name = currentValue)
      );
      this.contacts_orders = contacts_array;

      //Posicionamiento MAPS, marcador y vista mapa
      this.markerOptions.position.lat = this.project.latitude;
      this.markerOptions.position.lng = this.project.longitude;
      this.mapOptions.center.lat = this.project.latitude;
      this.mapOptions.center.lng = this.project.longitude;
    },
    _getEditLocation() {
      for (let x = 0; x < this.locations.length; x++) {
        for (let y = 0; y < this.locations[x].provinces.length; y++) {
          let data;
          data = this.locations[x].provinces[y].communes.filter((commune) => {
            return commune.id === this.project.commune.id;
          });
          if (data.length > 0) {
            this.editForm.region = this.locations[x].id;
            this.editForm.province = this.locations[x].provinces[y].id;
            this.editForm.commune = this.project.commune.id;

            if (this.editForm.region != "" && this.editForm.region != null) {
              this.provinces_list = this.locations.filter((locations) => {
                return locations.id === this.editForm.region;
              });
              this.provinces_list = this.provinces_list[0].provinces;
            }
            if (
              this.editForm.province != "" &&
              this.editForm.province != null
            ) {
              this.communes_list = this.provinces_list.filter((provinces) => {
                return provinces.id === this.editForm.province;
              });
              this.communes_list = this.communes_list[0].communes;
            }
          }
        }
      }
    },
    _getModels() {
      let params = {
        project_id: this.project.id,
        cache: Math.floor(Date.now() / 1000),
      };
      this.modelsByProject(params)
        .then((res) => {
          let all_models = res.data.modelsbyproject.models;
          let models_medias = res.data.modelsbyproject.modelswithmedias;
          all_models.forEach((model) => {
            const find_model = models_medias.find(
              (element) => element.model == model
            );
            if (typeof find_model == "undefined") {
              this.models_list.push({ name: model, disabled: true });
            } else {
              this.models_list.push({
                name: find_model.model,
                disabled: false,
              });
              this.project_models.push({
                media_id: find_model.floormedias[0].id,
                model: find_model.model,
                src: find_model.floormedias[0].full_url,
                new: false,
              });
            }
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al obtener modelos",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    _previewModel(event) {
      if (event.target.files[0] != null) {
        if (event.target.files[0].type === "image/png") {
          let index;
          index = this.models_list.findIndex((models) => {
            return models.name === this.model_selected;
          });
          this.models_list[index].disabled = false;
          this.project_models.push({
            file: event.target.files[0],
            model: this.model_selected,
            src: URL.createObjectURL(event.target.files[0]),
            new: true,
          });
          this.project_model = [];
          this.model_selected = "";
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Formato de archivo incorrecto",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    _deleteModel(position, img) {
      this.$swal({
        title: "¿Eliminar modelo " + img.model + "?",
        text: "El modelo se eliminará y tendrás que subir nuevamente una imagen.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this._confirmDelete(position, img);
        }
      });
    },
    async _confirmDelete(position, img) {
      if (!img.new) {
        await this.deleteMediaProject({
          project_id: this.project.id,
          media_id: img.media_id,
        })
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Se ha eliminado el modelo con éxito",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
            let index;
            index = this.models_list.findIndex((models) => {
              return models.name === img.model;
            });
            this.models_list[index].disabled = true;
            this.project_models.splice(position, 1);
          })
          .catch((err) => {
            this.errorEditProject = true;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error al eliminar modelo " + img.model,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.overlayForm = false;
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Se ha eliminado el modelo con éxito",
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
        let index;
        index = this.models_list.findIndex((models) => {
          return models.name === img.model;
        });
        this.models_list[index].disabled = true;
        this.project_models.splice(position, 1);
      }
    },
    _onHidden() {
      this.$refs.button.focus();
    },
  },
};
</script>

<style>
.con-vs-popup .vs-popup {
  width: auto !important;
}

.ckeditor {
  height: 150px;
  width: 100%;
}

.ck-content {
  height: 100px;
}

.divInfo {
  border-radius: 10px;
}

.flex {
  display: flex;
  align-items: center;
}

.inputLabel {
  background-color: #e7e7e7;
  color: black;
  padding: 8px;
  border-radius: 10px;
}

.inputLabel:hover {
  background-color: #ea387f;
  color: white;
}

.preview {
  border-radius: 10px;
  height: 200px;
  width: auto;
  background-color: #e7e7e7;
  text-align: center;
  padding: 90px 0;
}

.image {
  border-radius: 10px;
  width: auto;
  height: 200px;
  object-fit: cover;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.container {
  position: relative;
}

.middle {
  transition: 0.5s ease;
  opaprovince: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.map {
  height: 425px;
}

div.danger-text {
  color: red;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
